<script>
import moment from "moment";

export default {
    name: "driver-withdrawal-top",
    props: {
        info: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        driverCount() {
            return this.info.driverCount || {}
        },
        success() {
            return this.info.priceCount ? this.info.priceCount.find(({status}) => status === 3) || {} : {}
        },
        fail() {
            return this.info.priceCount ? this.info.priceCount.find(({status}) => status === 4) || {} : {}
        },
        inProgress() {
            return this.info.priceCount ? this.info.priceCount.find(({status}) => status === 2) || {} : {}
        },
        dateText() {
            return moment(this.info.withdrawData).isSame(moment(), 'd') ? '今日为提现日' : `上一提现日为${moment(this.info.withdrawData).format('M月D日')}`
        }
    },
    methods: {
        handleSetting() {
            this.$router.push('/driverWithdrawal/setting')
        }
    }
}
</script>

<template>
    <div class="driver-withdrawal-top">
        <p>{{ dateText }}，共有<span class="color-primary" v-if="driverCount">{{
                driverCount.driverCount
            }}</span>位司机申请提现，已成功提现<span class="color-primary" v-if="success">{{
                success.totalAccountCount
            }}</span>笔，共计<span class="color-danger" v-if="success">{{
                Math.abs(success.totalAccountPrice) | currency
            }}</span>元；失败<span v-if="fail">{{ fail.totalAccountCount }}</span>笔，共计<span v-if="fail">{{
                fail.totalAccountPrice | currency
            }}</span>元；提现中<span v-if="inProgress">{{ inProgress.totalAccountCount || 0 }}</span>笔，共计<span
            v-if="inProgress">{{
                (inProgress.totalAccountPrice || 0) | currency
            }}</span>元</p>
        <el-button type="primary" size="small" style="width: 154px;" @click="handleSetting">提现设置</el-button>
    </div>
</template>

<style scoped lang="scss">
.driver-withdrawal-top {
    background-color: #DFEFFF;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
