<script>
import comListSearch from "@/www/components/comListSearch/index.vue";
import selectCity from "@/components/selectCity/index.vue";
import supplierSelect from "@/www/components/supplier-select/index.vue";
import operatorSelect from "@/www/components/operator-select/index.vue";
import driverWithdrawalTop from "./components/driver-withdrawal-top.vue";
import {getWithdrawInfoListApi} from "@/www/urls/wallet";
import {combinationForm} from "@/common/js/common";
import moment from "moment";

const initForm = () => ({
    pageIndex: 1,
    pageSize: 10,
    date: [],
    status: -1
})
export default {
    name: "driverWithdrawalRecord",
    components: {
        comListSearch,
        selectCity,
        supplierSelect,
        operatorSelect,
        driverWithdrawalTop
    },
    data() {
        return {
            loading: false,
            total: 0,
            list: [],
            result: {},
            form: initForm(),
            statusOptions: [
                {
                    label: "转账中",
                    value: 2
                },
                {
                    label: "已转出",
                    value: 3
                },
                {
                    label: "转账异常",
                    value: 4
                },
            ],
        }
    },
    mounted() {
        if (this.$route.query.date)
            this.form.date = [moment(Number(this.$route.query.date)).startOf('d').toDate(), moment(Number(this.$route.query.date)).endOf('d').toDate()]
        this.getList()
    },
    methods: {
        async getList() {
            try {
                this.loading = true
                const ret = await getWithdrawInfoListApi({
                    ...combinationForm(this.form),
                    driverStatus: this.form.driverStatus === -1 ? undefined : this.form.driverStatus,
                    status: this.form.status === -1 ? undefined : this.form.status,
                    startCreateTime: this.form.date.length ? moment(this.form.date[0]).startOf('d').valueOf() : undefined,
                    endCreateTime: this.form.date.length ? moment(this.form.date[1]).endOf('d').valueOf() : undefined,
                    date: undefined
                })
                if (ret.success && ret.data) {
                    this.list = ret.data.pageList.data;
                    this.total = ret.data.pageList.total
                    this.result = ret.data
                }
            } finally {
                this.loading = false
            }
        },
        reset() {
            this.form = initForm()
            this.$refs['select-city'].clear()
            this.getList()
        },
        handleDetail(record) {
            this.$router.push('/driverWithdrawal/detail?info=' + JSON.stringify(record))
        }
    }
}
</script>

<template>
    <div>
        <el-main class="com-eTrip-section" :class="{'section-has-footer':list.length>0}" v-loading="loading">
            <div class="eTrip-section-cont driver-balance-list">
                <driver-withdrawal-top :info="result"></driver-withdrawal-top>
                <com-list-search>
                    <div slot="list-search-before">
                        <el-form inline size="medium">
                            <el-form-item>
                                <el-date-picker type="daterange" start-placeholder="开始日期"
                                                end-placeholder="结束日期" v-model="form.date"></el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-select placeholder="司机状态" v-model="form.driverStatus">
                                    <el-option label="全部" :value="-1">全部</el-option>
                                    <el-option label="可用" :value="1">可用</el-option>
                                    <el-option label="不可用" :value="0">不可用</el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item>
                                <select-city ref="select-city" placeholder="服务城市" :is-border="false" size="medium"
                                             @update:city="form.cityId=$event.shortCode"></select-city>
                            </el-form-item>
                            <el-form-item>
                                <operator-select placeholder="所属运营商" v-model="form.operatorId"></operator-select>
                            </el-form-item>
                            <el-form-item>
                                <supplier-select placeholder="所属供应商" v-model="form.supplierId"></supplier-select>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="司机姓名" clearable v-model="form.driverName"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-input placeholder="司机手机" type="tel" :max-length="11" clearable
                                          v-model="form.driverMobile"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-radio-group placeholder="转账状态" v-model="form.status">
                                    <el-radio-button :label="-1">全部</el-radio-button>
                                    <el-radio-button v-for="vm in statusOptions" :label="vm.value" :key="vm.value">
                                        {{ vm.label }}
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div slot="list-search-after">
                        <el-button @click="reset()">重置</el-button>
                        <el-button type="primary" @click="getList(1)">筛选</el-button>
                    </div>
                </com-list-search>
                <el-table :data="list">
                    <el-table-column label="提现时间" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | dateCus }}
                        </template>
                    </el-table-column>
                    <el-table-column label="司机姓名/手机" prop="driverName" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.driverName }}{{ scope.row.driverMobile }}
                        </template>
                    </el-table-column>
                    <el-table-column label="提现账户" prop="driverName" width="160">
                        <template slot-scope="scope">
                            {{ scope.row.realName || '' }}{{ scope.row.aliPayAccount }}
                        </template>
                    </el-table-column>
                    <el-table-column label="服务城市" width="120" prop="cityName"></el-table-column>
                    <el-table-column label="所属运营商" width="120" prop="operatorName"></el-table-column>
                    <el-table-column label="所属供应商" prop="supplierName"></el-table-column>
                    <el-table-column label="司机状态" width="120" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.driverStatus === 1 ? '可用' : '不可用' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="提现金额" prop="accountPrice"></el-table-column>
                    <el-table-column label="提现状态" width="120" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.status | formatDesc(statusOptions) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作项">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="handleDetail(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px" v-if="list.length>0">
            <div class="eTrip-footer-cont">
                <el-pagination
                    @current-change="getList"
                    :current-page.sync="form.pageIndex"
                    :page-size="form.pageSize"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </div>
        </el-footer>
    </div>
</template>

<style scoped lang="scss">
.eTrip-footer-cont {
    display: flex;
    justify-content: flex-end;
    padding-right: 0;
}
</style>
